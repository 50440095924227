import "swiper/css";
import "swiper/css/navigation";
import "../../../../../styles.css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import TrophyData from "./TrophyData";
import { useState } from "react";
const Trophy = () => {
  const [hoveredStates, setHoveredStates] = useState(
    new Array(TrophyData.length).fill(false)
  );

  const handleHover = (index) => {
    setHoveredStates((prevStates) =>
      prevStates.map((hovered, i) => (i === index ? !hovered : hovered))
    );
  };
  //   const [isHovered, setIsHovered] = useState(false);
  //   const handleHover = () => {
  //     setIsHovered(!isHovered);
  //   };
  return (
    <>
      <div className="container mx-auto p-5 mt-10">
        <div className="text-center text-white">
          <p className="text-3xl my-3">افتخارات</p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          slidesPerGroup={1}
          navigation={true}
          modules={[Navigation]}
          centeredSlides={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            870: {
              slidesPerView: 5,
            },
          }}
          className=""
        >
          {TrophyData?.map((trophy, index) => {
            return (
              <SwiperSlide key={trophy.id}>
                <img
                  className="w-1/2 inline-block rounded-lg"
                  src={hoveredStates[index] ? trophy.hover : trophy.cover}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => handleHover(index)}
                  onClick={() => handleHover(index)}
                  //   src={isHovered ? trophy.hover : trophy.cover}
                  //   onMouseEnter={handleHover}
                  //   onMouseLeave={handleHover}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default Trophy;
