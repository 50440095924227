import t1 from "../../../../../Images/1.jpg";
import t2 from "../../../../../Images/2.jpg";
import t3 from "../../../../../Images/3.jpg";
import t4 from "../../../../../Images/4.jpg";
import t5 from "../../../../../Images/5.jpg";
import t6 from "../../../../../Images/6.jpg";
import t7 from "../../../../../Images/7.jpg";
import t8 from "../../../../../Images/8.jpg";
import t9 from "../../../../../Images/9.jpg";
import t10 from "../../../../../Images/10.jpg";
import t11 from "../../../../../Images/11.jpg";
import t12 from "../../../../../Images/12.jpg";
import t13 from "../../../../../Images/13.jpg";
import t14 from "../../../../../Images/14.jpg";
import t15 from "../../../../../Images/15.jpg";
import t16 from "../../../../../Images/16.jpg";
import t17 from "../../../../../Images/17.jpg";
import t18 from "../../../../../Images/18.jpg";
import t19 from "../../../../../Images/19.jpg";
import t20 from "../../../../../Images/20.jpg";
import t21 from "../../../../../Images/21.jpg";
import t22 from "../../../../../Images/22.jpg";

let item = [
  {
    id: "1",
    cover: t1,
    hover: t2,
  },
  {
    id: "2",
    cover: t3,
    hover: t4,
  },
  {
    id: "3",
    cover: t5,
    hover: t6,
  },
  {
    id: "4",
    cover: t7,
    hover: t8,
  },
  {
    id: "5",
    cover: t9,
    hover: t10,
  },
  {
    id: "6",
    cover: t11,
    hover: t12,
  },
  {
    id: "7",
    cover: t13,
    hover: t14,
  },
  {
    id: "8",
    cover: t15,
    hover: t16,
  },
  {
    id: "9",
    cover: t17,
    hover: t18,
  },
  {
    id: "10",
    cover: t19,
    hover: t20,
  },
  {
    id: "11",
    cover: t21,
    hover: t22,
  },
];

export default item;
