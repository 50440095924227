import Categories from "./Categories/Categories";
import Footer from "../Footer/Footer";
import Header from "../Header/Navbar";
import Slogun from "./Slogun/Slogun";
import Productlist from "./Productlist/Productlist";
import About from "./About/About";
import Blog from "./Blog/Blog";
import AuctionBanner from "./AuctionBanner/AuctionBanner";
import Trophy from "./Trophy/Trophy";

const Index = () => {
  return (
    <>
      <div className="bghome text-center PersianFont">
        <Header />
        <Slogun />
        <Categories />
        <p className="text-base text-[#00D777]">محصولات</p>
        <h2 className="text-xl text-white  mb-10 md:text-3xl PersianBold">
          جدیدترین محصولات ما
        </h2>
        <Productlist />
        <About />
        <Blog />
        <Trophy />
        <AuctionBanner />
        {/* <Map /> */}
        <Footer />
      </div>
    </>
  );
};
export default Index;
