import productsData from "./productsData";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "../../styles.css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Productlist = () => {
  return (
    <>
      <div className="p-5 container mx-auto">
        <div className="product text-center container mx-auto">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            slidesPerGroup={1}
            navigation={true}
            modules={[Navigation]}
            // centeredSlides={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              870: {
                slidesPerView: 4,
              },
            }}
            className="myproduct"
          >
            {productsData?.map((product) => {
              return (
                <SwiperSlide key={product.id}>
                  <div className="mb-4 lg:mb-0 inline-block">
                    <Link to={`/products/${product.id}`}>
                      <div className="p1 w-11/12">
                        <img
                          className="w-full rounded-t-3xl"
                          src={product.coverimage}
                        ></img>
                      </div>
                      <div className="w-11/12 border-[#143591] border rounded-b-lg p-3 pt-0">
                        <h3 className="text-white text-left">
                          {product.titleb}
                        </h3>
                        <p className="text-left text-xs md:text-base text-[#c0bfba]">
                          {product.title}
                        </p>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default Productlist;
